import { createContext, useState } from "react";
import { Pharmacy } from "../types";

const OrdersContext = createContext<any>(null);

export const OrdersProvider = ({ children }: any) => {
  const [orders, setOrders] = useState<any[]>([]);
  const [cart, setCart] = useState<any[]>([]);
  const [selectedPharmacy, setSelectedPharmacy] = useState<string>("");
  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
  const [openBundlePackModal, setOpenBundlePackModal] = useState<number>(-1);
  const [orderError, setOrderError] = useState<
    | boolean
    | {
        message: string;
        errors: { [key: string]: string[] };
      }
  >(false);
  const [reposition, setReposition] = useState<boolean>(false);
  const [repositionDiscount, setRepositionDiscount] = useState<number>(0);
  const [replenishment, setReplenishment] = useState<boolean>(false);

  return (
    <OrdersContext.Provider
      value={{
        cart,
        orders,
        orderError,
        pharmacies,
        replenishment,
        reposition,
        repositionDiscount,
        selectedPharmacy,
        setCart,
        setOrders,
        setOrderError,
        setPharmacies,
        setReplenishment,
        setReposition,
        setRepositionDiscount,
        setSelectedPharmacy,
        setOpenBundlePackModal,
        openBundlePackModal,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export default OrdersContext;
