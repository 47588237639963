export const DBConfig = {
  databaseName: "transfers2.0",
  version: 1,
  stores: [
    {
      name: "product",
      id: { keyPath: "id", autoIncrement: true },
      indices: [
        {
          name: "bundle_packs",
          keyPath: "bundle_packs",
          options: { unique: false },
        },
        { name: "cn", keyPath: "cn", options: { unique: false } },
        {
          name: "description",
          keyPath: "description",
          options: { unique: false },
        },
        {
          name: "discount_scaling_1",
          keyPath: "discount_scaling_1",
          options: { unique: false },
        },
        {
          name: "discount_scaling_2",
          keyPath: "discount_scaling_2",
          options: { unique: false },
        },
        {
          name: "discount_scaling_3",
          keyPath: "discount_scaling_3",
          options: { unique: false },
        },
        {
          name: "discount_scaling_4",
          keyPath: "discount_scaling_4",
          options: { unique: false },
        },
        {
          name: "discount_scaling_5",
          keyPath: "discount_scaling_5",
          options: { unique: false },
        },
        {
          name: "discount_scaling_6",
          keyPath: "discount_scaling_6",
          options: { unique: false },
        },
        {
          name: "discount_scaling_7",
          keyPath: "discount_scaling_7",
          options: { unique: false },
        },
        {
          name: "discount_scaling_8",
          keyPath: "discount_scaling_8",
          options: { unique: false },
        },
        {
          name: "discount_scaling_9",
          keyPath: "discount_scaling_9",
          options: { unique: false },
        },
        {
          name: "equivalence_surcharge",
          keyPath: "equivalence_surcharge",
          options: { unique: false },
        },
        {
          name: "num_items_scaling_1",
          keyPath: "num_items_scaling_1",
          options: { unique: false },
        },
        {
          name: "num_items_scaling_2",
          keyPath: "num_items_scaling_2",
          options: { unique: false },
        },
        {
          name: "num_items_scaling_3",
          keyPath: "num_items_scaling_3",
          options: { unique: false },
        },
        {
          name: "num_items_scaling_4",
          keyPath: "num_items_scaling_4",
          options: { unique: false },
        },
        {
          name: "num_items_scaling_5",
          keyPath: "num_items_scaling_5",
          options: { unique: false },
        },
        {
          name: "num_items_scaling_6",
          keyPath: "num_items_scaling_6",
          options: { unique: false },
        },
        {
          name: "num_items_scaling_7",
          keyPath: "num_items_scaling_7",
          options: { unique: false },
        },
        {
          name: "num_items_scaling_8",
          keyPath: "num_items_scaling_8",
          options: { unique: false },
        },
        {
          name: "num_items_scaling_9",
          keyPath: "num_items_scaling_9",
          options: { unique: false },
        },
        { name: "price", keyPath: "price", options: { unique: false } },
        { name: "pvl", keyPath: "pvl", options: { unique: false } },
        { name: "pvp", keyPath: "pvp", options: { unique: false } },
        {
          name: "vat",
          keyPath: "vat",
          options: { unique: false },
        },
        {
          name: "reposition",
          keyPath: "reposition",
          options: { unique: false },
        },
      ],
    },
    {
      name: "order",
      id: { keyPath: "id", autoIncrement: true },
      indices: [{ name: "code", keyPath: "code", options: { unique: false } }],
    },
    {
      name: "general",
      id: { keyPath: "id", autoIncrement: true },
      indices: [
        {
          name: "last_updated_at",
          keyPath: "last_updated_at",
          options: { unique: false },
        },
        {
          name: "public_key",
          keyPath: "public_key",
          options: { unique: false },
        },
        {
          name: "private_key",
          keyPath: "private_key",
          options: { unique: false },
        },
      ],
    },
    {
      name: "pharmacy",
      id: { keyPath: "id", autoIncrement: true },
      indices: [
        { name: "code", keyPath: "code", options: { unique: true } },
        {
          name: "name",
          keyPath: "name",
          options: { unique: false },
        },
      ],
    },
  ],
};
