import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import install from "@twind/with-react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { LoginProvider } from "./context/LoginContext";
import { OrdersProvider } from "./context/OrdersContext";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./services/i18n";
import config from "./twind.config";

// Twind
install(config);

const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSCLIENTID!!,
    authority: "https://login.microsoftonline.com/common",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
const pca = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <LoginProvider>
        <OrdersProvider>
          <App />
        </OrdersProvider>
      </LoginProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    registration.unregister().then(() => {
      registration.update();
      console.info("update");
    });
  },
  onSuccess: (registration) => {
    console.info("success");
  },
});
