import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import setupIndexedDB from "use-indexeddb";
import packageJson from "../package.json";
import "./App.css";
import { DBConfig } from "./DBConfig";
import Cart from "./components/Cart";
import LoadingOverlay from "./components/LoadingOverlay";
import Login from "./components/Login";
import LoginContext from "./context/LoginContext";
import useData from "./hooks/useData";
import PharmacySelector from "./components/PharmacySelector";
import OrdersContext from "./context/OrdersContext";

const ProductTable = lazy(() => import("./components/ProductTable"));

function App() {
  const { products, loading, fetchData, ready, generateOrder, syncDataFromDb } =
    useData();
  const { selectedPharmacy } = useContext(OrdersContext);

  const appVersion = packageJson.version || 3.9;

  const { t } = useTranslation();
  const { loaded, loginRequired, getMsUrl } = useContext(LoginContext);
  const [development] = useState<boolean>(
    process.env.NODE_ENV === "development" ||
      window.location.hostname.includes("enco.")
  );

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isServiceWorkerReady, setIsServiceWorkerReady] =
    useState<boolean>(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState<boolean>(false);
  const [marqueeText, setMarqueeText] = useState<string>("");
  const [counter, setCounter] = useState<number>(30);

  // start counting down if loaded or ready is false, once the counter reaches 0, reload the page.
  useEffect(() => {
    if (loaded || ready) return;
    const interval = setInterval(() => {
      setCounter((prev) => prev - 1);
      setMarqueeText(`Reloading in ${counter} seconds...`);
    }, 1000);
    return () => clearInterval(interval);
  }, [loaded, ready]);

  useEffect(() => {
    setupIndexedDB(DBConfig)
      .then(() => {
        if (!navigator.onLine) {
          syncDataFromDb();
        }
      })
      .catch((e) => console.error("error / unsupported", e));
  }, []);

  const updateApp = () => {
    setMarqueeText("Updating App...");
    setTimeout(async () => {
      window.location.reload();
    }, 3000);
  };

  useEffect(() => {
    const onOffline = () => setIsOnline(false);
    const onOnline = () => setIsOnline(true);
    const onUpdate = () => setIsUpdateAvailable(true);
    const onUpdateReady = () => {
      console.log("update ready");
      setIsServiceWorkerReady(true);
    };

    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);
    window.addEventListener("update", onUpdate);
    window.addEventListener("sw-update", onUpdate);
    window.addEventListener("updateReady", onUpdateReady);
    window.addEventListener("sw-ready", onUpdateReady);
    window.addEventListener("activate", updateApp);
    if (development) {
      document.title = "DEV - Transfers";
    }

    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
      window.removeEventListener("update", onUpdate);
      window.removeEventListener("updateReady", onUpdateReady);
      window.removeEventListener("sw-ready", onUpdateReady);
      window.removeEventListener("activate", updateApp);
    };
  }, []);

  useEffect(() => {
    if (isUpdateAvailable) {
      setMarqueeText("Update available, reloading...");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [isUpdateAvailable]);

  useEffect(() => {
    if (isServiceWorkerReady) {
      setMarqueeText("Service worker ready");
    } else {
      setMarqueeText("Initializing...");
    }
  }, [isServiceWorkerReady]);

  useEffect(() => {
    if (!ready) return;
    const pullData = async () => {
      setMarqueeText("Fetching data...");

      await fetchData();

      setTimeout(() => {
        setMarqueeText(
          `${t("Última actualización")} ${new Date().toLocaleString()}`
        );
      }, 3000);
    };

    if (navigator.onLine) {
      pullData();
    }
  }, [ready, navigator.onLine]);

  if (loginRequired) {
    return (
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-[100] w-screen h-screen">
        <Login onClick={getMsUrl} />
      </div>
    );
  }

  if (loading || products.length === 0 || !ready || !loaded) {
    return <LoadingOverlay counter={counter} />;
  }

  return (
    <div className="relative bg-white">
      {development && (
        <div className="bg-[#ffed00] w-full text-center font-bold">
          DEVELOPMENT MODE
        </div>
      )}
      <header className="bg-gray-100 flex w-full">
        {/* marquee header */}
        <div
          className={`text-xs text-gray-600 w-full animate-marquee text-right ${
            isOnline ? "text-green-800" : ""
          }`}
        >
          v.{appVersion} - {isOnline ? "Online" : "Offline"} - {marqueeText}
        </div>
      </header>
      <main className="bg-white flex flex-col w-full justify-start items-start">
        <Suspense fallback={<LoadingOverlay />}>
          <div className="flex flex-row w-full justify-center">
            <h1 className="text-2xl font-bold text-primary">
              {t("ENTRADA DE PEDIDOS")}
            </h1>
          </div>

          <section className="flex w-full justify-center px-2 flex-col gap-4">
            <div className="flex flex-col justify-start gap-4 w-full relative">
              <PharmacySelector />
            </div>
            <div className="flex xl:flex-row flex-col w-full justify-between items-start gap-4 xl:gap-2">
              {selectedPharmacy === "" && (
                <div className="w-full h-full pb-44 flex justify-center items-center fixed left-0 z-50 bg-white bg-opacity-80">
                  <div className="flex flex-col items-center justify-center h-full"></div>
                </div>
              )}
              <div className="xl:w-[4/5] w-full flex justify-center border xl:order-none order-first h-full">
                <ProductTable products={products} />
              </div>

              <div className="xl:w-2/4 w-full flex justify-center flex-col items-start">
                <Cart generateOrder={generateOrder} />
              </div>
            </div>
          </section>
        </Suspense>
      </main>
    </div>
  );
}

export default App;
