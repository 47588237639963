import { useTranslation } from 'react-i18next';

const DeleteBundlePackModal = ({
	onDeleteConfirm,
	onCancel,
}: {
	onDeleteConfirm: () => void;
	onCancel: () => void;
}) => {
	const { t } = useTranslation();
	return (
		<div className='fixed flex items-center z-[20] justify-center bg-black bg-opacity-50 inset-0 p-4'>
			<div className='bg-white rounded-md flex flex-col justify-center items-center p-4 gap-2'>
				<h1 className='font-bold text-primary text-lg'>
					{t('Esto eliminará el surtido de productos, ¿estás seguro?')}
				</h1>
				<div className='flex flex-row justify-center items-center gap-4'>
					<button
						className='bg-soft-gray text-black px-2 py-1 rounded-xs focus:outline-none hover:bg-gray-200 transition-color duration-200 ease-in-out'
						onClick={onCancel}
					>
						{t('Cerrar')}
					</button>
					<button
						className='bg-primary text-white px-2 py-1 rounded-xs hover:shadow-md focus:outline-none transition-color duration-200 ease-in-out'
						onClick={() => {
							onDeleteConfirm();
						}}
					>
						{t('Eliminar')}
					</button>
				</div>
			</div>
		</div>
	);
};

export default DeleteBundlePackModal;
