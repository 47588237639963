import { Product } from "../types";

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const productHasDiscount = (product: Product) => {
  if (product.from_bundle_id) {
    return (
      product.discount_scaling_5 !== null ||
      product.discount_scaling_6 !== null ||
      product.discount_scaling_7 !== null ||
      product.discount_scaling_8 !== null ||
      product.discount_scaling_9 !== null
    );
  }

  return (
    product.discount_scaling_1 !== null ||
    product.discount_scaling_2 !== null ||
    product.discount_scaling_3 !== null ||
    product.discount_scaling_4 !== null ||
    product.discount_scaling_5 !== null ||
    product.discount_scaling_6 !== null ||
    product.discount_scaling_7 !== null ||
    product.discount_scaling_8 !== null ||
    product.discount_scaling_9 !== null
  );
};

export const minimunQty = (product: Product, reposition = false) => {
  if (reposition) {
    return 1;
  }

  let result;

  if (product.from_bundle_id) {
    for (let i = 5; i <= 9; i++) {
      if (product[`num_items_scaling_${i}` as keyof Product]) {
        result = product[`num_items_scaling_${i}` as keyof Product];
        break;
      }
    }
    return Number(result);
  }

  for (let i = 1; i <= 9; i++) {
    if (product[`num_items_scaling_${i}` as keyof Product]) {
      result = product[`num_items_scaling_${i}` as keyof Product];
      break;
    }
  }
  return Number(result);
};

export const calculatePrice = (
  product: Product,
  selectedProducts: any,
  repositionDiscount: number = 0
) => {
  const selectedProduct = selectedProducts.find(
    (p: any) => p.cn === product.cn
  );
  let discount = 0;

  if (selectedProduct) {
    const qty = selectedProduct.quantity || 0;
    if (qty === 0) return 0;

    const productPrice = product.pvl;

    if (repositionDiscount) {
      discount = (repositionDiscount * productPrice) / 100;
    } else {
      if (
        product.num_items_scaling_1 &&
        parseInt(qty) >= product.num_items_scaling_1 &&
        parseInt(qty) < (product.num_items_scaling_2 || qty + 1)
      ) {
        discount = (product.discount_scaling_1 * productPrice) / 100;
      }
      if (
        product.num_items_scaling_2 &&
        parseInt(qty) >= product.num_items_scaling_2 &&
        parseInt(qty) < (product.num_items_scaling_3 || qty + 1)
      ) {
        discount = (productPrice * product.discount_scaling_2) / 100;
      }
      if (
        product.num_items_scaling_3 &&
        parseInt(qty) >= product.num_items_scaling_3 &&
        parseInt(qty) < (product.num_items_scaling_4 || qty + 1)
      ) {
        discount = (productPrice * product.discount_scaling_3) / 100;
      }
      if (
        product.num_items_scaling_4 &&
        parseInt(qty) >= product.num_items_scaling_4 &&
        parseInt(qty) < (product.num_items_scaling_5 || qty + 1)
      ) {
        discount = (productPrice * product.discount_scaling_4) / 100;
      }
      if (
        product.num_items_scaling_5 &&
        parseInt(qty) >= product.num_items_scaling_5 &&
        parseInt(qty) < (product.num_items_scaling_6 || qty + 1)
      ) {
        discount = (productPrice * product.discount_scaling_5) / 100;
      }
      if (
        product.num_items_scaling_6 &&
        parseInt(qty) >= product.num_items_scaling_6 &&
        parseInt(qty) < (product.num_items_scaling_7 || qty + 1)
      ) {
        discount = (productPrice * product.discount_scaling_6) / 100;
      }
      if (
        product.num_items_scaling_7 &&
        parseInt(qty) >= product.num_items_scaling_7 &&
        parseInt(qty) < (product.num_items_scaling_8 || qty + 1)
      ) {
        discount = (productPrice * product.discount_scaling_7) / 100;
      }
      if (
        product.num_items_scaling_8 &&
        parseInt(qty) >= product.num_items_scaling_8 &&
        parseInt(qty) < (product.num_items_scaling_9 || qty + 1)
      ) {
        discount = (productPrice * product.discount_scaling_8) / 100;
      }
      if (
        product.num_items_scaling_9 &&
        parseInt(qty) >= product.num_items_scaling_9
      ) {
        discount = (productPrice * product.discount_scaling_9) / 100;
      }
    }

    return (productPrice - discount) * qty;
  }

  return discount * product.pvl;
};

export const calculateDiscount = (product: Product) => {
  const quantity = product.quantity || 0;
  const price = product.pvl || 0;

  if (product.from_bundle_id !== undefined) {
    if (
      product.num_items_scaling_5 &&
      quantity >= product.num_items_scaling_5 &&
      quantity < (product.num_items_scaling_6 || quantity + 1)
    ) {
      return [
        product.discount_scaling_5,
        price - (product.discount_scaling_5 * price) / 100,
      ];
    }
    if (
      product.num_items_scaling_6 &&
      quantity >= product.num_items_scaling_6 &&
      quantity < (product.num_items_scaling_7 || quantity + 1)
    ) {
      return [
        product.discount_scaling_6,
        price - (product.discount_scaling_6 * price) / 100,
      ];
    }
    if (
      product.num_items_scaling_7 &&
      quantity >= product.num_items_scaling_7 &&
      quantity < (product.num_items_scaling_8 || quantity + 1)
    ) {
      return [
        product.discount_scaling_7,
        price - (product.discount_scaling_7 * price) / 100,
      ];
    }
    if (
      product.num_items_scaling_8 &&
      quantity >= product.num_items_scaling_8 &&
      quantity < (product.num_items_scaling_9 || quantity + 1)
    ) {
      return [
        product.discount_scaling_8,
        price - (product.discount_scaling_8 * price) / 100,
      ];
    }
    if (
      product.num_items_scaling_9 &&
      quantity >= product.num_items_scaling_9
    ) {
      return [
        product.discount_scaling_9,
        price - (product.discount_scaling_9 * price) / 100,
      ];
    }
  }

  let appliedDiscount = 0;
  const scalingDiscounts = [
    product.discount_scaling_1,
    product.discount_scaling_2,
    product.discount_scaling_3,
    product.discount_scaling_4,
    product.discount_scaling_5,
    product.discount_scaling_6,
    product.discount_scaling_7,
    product.discount_scaling_8,
    product.discount_scaling_9,
  ];

  for (let i = 0; i < scalingDiscounts.length; i++) {
    const scaling = product[`num_items_scaling_${i + 1}` as keyof Product];
    if (scaling && quantity >= Number(scaling)) {
      appliedDiscount = scalingDiscounts[i] || 0;
    }
  }

  return [appliedDiscount, price - (appliedDiscount * price) / 100];
};

export const appliedDiscount = (
  product: Product,
  qty: number,
  replenishment = false,
  repositionDiscount = 0
): number => {
  const discount = 0;

  if (qty === 0) return discount;

  if (replenishment) {
    return repositionDiscount;
  }

  if (product.from_bundle_id) {
    const max = 5; // 5 scaling and 4 discount for bundle pack
    for (let i = 1; i <= max; i++) {
      const index = i + 4;
      const numItems = Number(
        product[`num_items_scaling_${index}` as keyof Product]
      );
      const discountScaling =
        product[`discount_scaling_${index}` as keyof Product];
      if (
        numItems &&
        product[`num_items_scaling_${index}` as keyof Product] !== null &&
        qty >= numItems &&
        qty <
          Number(
            product[`num_items_scaling_${index + 1}` as keyof Product] ||
              qty + 1
          )
      ) {
        return discountScaling as number;
      }
    }
    return 0;
  }

  let appliedDiscount = 0;
  const scalingDiscounts = [
    product.discount_scaling_1,
    product.discount_scaling_2,
    product.discount_scaling_3,
    product.discount_scaling_4,
    product.discount_scaling_5,
    product.discount_scaling_6,
    product.discount_scaling_7,
    product.discount_scaling_8,
    product.discount_scaling_9,
  ];

  for (let i = 0; i < scalingDiscounts.length; i++) {
    const scaling = product[`num_items_scaling_${i + 1}` as keyof Product];
    if (scaling && qty >= Number(scaling)) {
      appliedDiscount = scalingDiscounts[i] || 0;
    }
  }

  return appliedDiscount;
};

export const isBundleInCart = (product: Product, cart: Product[]) => {
  return (
    cart.find(
      (p: any) => p.cn === product.cn && p.from_bundle_id !== undefined
    ) !== undefined
  );
};

export const calculateMargin = (p: Product, qty: number) => {
  // % Marge unitari = [ PVP - [PVL * (1+(IVA/100)+(RE/100)) * (1-(Dte/100))] ]/ PVP * 100
  const discount: number = appliedDiscount(p, qty);

  // prettier-ignore
  const result = Math.round(
		(p.pvp_recommended -
			(p.pvl *
				(1 +( p.vat / 100) + (p.equivalence_surcharge / 100)) *
				(1 - (discount / 100)))) /
			p.pvp_recommended *
			100,
	);

  if (result === Infinity) return 0;
  if (result === -Infinity) return 0;

  return isNaN(result) ? 0 : result;
};

export const calculateRepositionMargin = (p: Product, discount: number) => {
  // % Marge unitari = [ PVP - [PVL * (1+(IVA/100)+(RE/100)) * (1-(Dte/100))] ]/ PVP * 100
  // prettier-ignore
  const result = Math.round(
		(p.pvp_recommended -
			(p.pvl *
				(1 + (p.vat / 100) + (p.equivalence_surcharge / 100)) *
				(1 - (discount / 100)))) /
			p.pvp_recommended *
			100,
	);

  if (result === Infinity) return 0;
  if (result === -Infinity) return 0;

  return isNaN(result) ? 0 : Math.abs(result);
};

const calculateDiscountReposition = (p: Product, discount: number) => {
  const price = p.pvl;
  return price - (discount * price) / 100;
};

export const calculatePriceLine = (
  p: Product,
  cart: any[],
  reposition: boolean = false,
  repositionDiscount: number = 0
) => {
  if (p.from_bundle_id === undefined) {
    let discount = !reposition
      ? calculateDiscount(p)[1]
      : calculateDiscountReposition(p, repositionDiscount);

    return discount * p.quantity;
  } else {
    const bundlePackId = p.from_bundle_id;
    const productFromBundlePack = cart.filter(
      (product: Product) => product.from_bundle_id === bundlePackId
    );
    const totalProductBundlePack = productFromBundlePack.reduce(
      (acc: number, product: Product) => acc + product.quantity,
      0
    );

    return (
      calculateDiscount({ ...p, quantity: totalProductBundlePack })[1] *
      p.quantity
    );
  }
};

export const getTotalMargin = (
  cart: any[],
  reposition: boolean = false,
  repositionDiscount: number = 0
) => {
  let total = 0;
  let totalProducts = 0;

  let productsBundlePack = cart.filter(
    (product: any) => product.from_bundle_id !== undefined
  );

  let totalProductBundlePack = productsBundlePack.reduce(
    (acc: number, product: any) => acc + product.quantity,
    0
  );

  productsBundlePack.forEach((product: Product) => {
    if (product.pvp_recommended !== 0) {
      total += calculateMargin(product, totalProductBundlePack);
      totalProducts++;
    }
  });

  cart.forEach((product: Product) => {
    if (product.pvp_recommended !== 0 && product.from_bundle_id === undefined) {
      total += !reposition
        ? calculateMargin(product, product.quantity)
        : calculateRepositionMargin(product, repositionDiscount);
      totalProducts++;
    }
  });

  return total === 0 ? 0 : total / totalProducts;
};

export const getTotal = (
  cart: any[],
  reposition: boolean = false,
  repositionDiscount: number = 0
) => {
  let total = 0;
  cart.forEach((product: any) => {
    total += calculatePriceLine(product, cart, reposition, repositionDiscount);
  });

  return total;
};

export const calculateDtoLine = (p: Product, cart: any[]) => {
  if (p.from_bundle_id === undefined) {
    return calculateDiscount(p)[0];
  } else {
    const bundlePackId = p.from_bundle_id;
    const productFromBundlePack = cart.filter(
      (product: Product) => product.from_bundle_id === bundlePackId
    );
    const totalProductBundlePack = productFromBundlePack.reduce(
      (acc: number, product: Product) => acc + product.quantity,
      0
    );

    return appliedDiscount(p, totalProductBundlePack);
  }
};
