import { useTranslation } from "react-i18next";

interface ConfirmOrderModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  loading: boolean;
}

const ConfirmOrderModal = (props: ConfirmOrderModalProps) => {
  const { t } = useTranslation();
  const { onConfirm, onCancel, loading } = props;

  return (
    <div className="fixed flex items-center z-[20] justify-center bg-black bg-opacity-50 inset-0">
      <div className="bg-white rounded-md flex flex-col justify-center items-center p-4 gap-2">
        <h1 className="font-bold text-primary text-lg">
          {t("Se generará el pedido en curso. ¿Desea continuar?")}
        </h1>
        {loading && (
          <div className="flex justify-center items-center flex-col gap-4">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary text-lg" />
            {t("Generando pedido...")}
          </div>
        )}
        <div className="flex flex-row justify-center items-center gap-4">
          <button
            className="bg-secondary text-white px-2 py-1 rounded-xs focus:outline-none hover:bg-gray-200 transition-color duration-200 ease-in-out"
            onClick={onCancel}
            disabled={loading}
          >
            {t("Cancelar")}
          </button>
          <button
            className="bg-primary text-white px-2 py-1 rounded-xs hover:shadow-md focus:outline-none transition-color duration-200 ease-in-out"
            onClick={onConfirm}
            disabled={loading}
          >
            {t("Aceptar")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOrderModal;
