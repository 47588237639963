import { defineConfig } from "@twind/core";
import presetAutoprefix from "@twind/preset-autoprefix";
import presetTailwind from "@twind/preset-tailwind";

export default defineConfig({
  presets: [presetAutoprefix(), presetTailwind()],
  darkMode: "class",
  theme: {
    extend: {
      colors: {
        primary: "#AF1685",
        secondary: "#333333",
        tertiary: "#333333",
        quaternary: "#F7E8F3",
        quinary: "#ffffff",
        "soft-gray": "#F0f5f9",
        "medium-gray": "#C9D6DF",
        "dark-gray": "#333333",
        "green-brand-2": "#49802A",
        "disabled-primary": "#F3F2E9",
        "disabled-secondary": "#E2E2E2",
      },
      borderRadius: {
        xs: "1px",
      },
      fontSize: {
        xl: "24px",
        lg: "20px",
        base: "13px",
        sm: "12px",
      },
      borderColor: {
        primary: "#AF1685",
        secondary: "#751e52",
        tertiary: "#333333",
        quaternary: "#F7E8F3",
        quinary: "#ffffff",
        "light-gray": "#FAFAFA",
      },
      backgroundColor: {
        "light-gray": "#FAFAFA",
        "border-gray": "#EEEEF0",
        "soft-gray": "#F0f5f9",
        "medium-gray": "#C9D6DF",
        "dark-gray": "#333333",
        "brand-4": "#F7E8F3",
        "disabled-primary": "#F3F2E9",
        "disabled-secondary": "#E2E2E2",
      },
      keyframes: {
        "slide-in-x": {
          "0%": { opacity: 0, transform: "translateX(100%)" },
          "100%": { opacity: 1, transform: "translateX(0)" },
        },
        "slide-in-y": {
          "0%": { opacity: 0, transform: "translateY(100%)" },
          "100%": { opacity: 1, transform: "translateY(0)" },
        },
        "fade-out-zoom": {
          "0%": { opacity: 1, transform: "scale(1)" },
          "100%": { opacity: 0, transform: "scale(0)" },
        },
        typewriter: {
          to: {
            left: "100%",
          },
        },
        blink: {
          "0%": {
            opacity: "0",
          },
          "0.1%": {
            opacity: "1",
          },
          "50%": {
            opacity: "1",
          },
          "50.1%": {
            opacity: "0",
          },
          "100%": {
            opacity: "0",
          },
        },
        marquee: {
          "0%": { transform: "translateX(0%)" },
          "100%": { transform: "translateX(-100%)" },
        },
        "blink-border": {
          "0%": {
            borderColor: "#AF1685",
          },
          "50%": {
            borderColor: "transparent",
          },
          "100%": {
            borderColor: "#AF1685",
          },
        },
      },
      animation: {
        "slide-in-x": "slide-in-x .25s ease-in-out forwards var(--delay, 0)",
        "slide-in-y": "slide-in-y .25s ease-in-out forwards var(--delay, 0)",
        "fade-out-zoom":
          "fade-out-zoom .25s ease-in-out forwards var(--delay, 0)",
        typewriter: "typewriter 2s steps(--steps) forwards",
        caret:
          "typewriter 2s steps(--step) forwards, blink 1s steps(11) infinite 2s",
        marquee: "marquee 25s linear infinite",
        "blinking-border": "blink-border 1s infinite",
      },
    },
    fontFamily: {
      sans: ["Aeonik", "sans-serif"],
    },
  },
});
