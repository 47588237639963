import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface LoginProps {
  onClick?: () => Promise<{ url: string }>; // getMsUrl
}

const Login = ({ onClick }: LoginProps) => {
  const [seconds, setSeconds] = useState(60);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="absolute w-screen h-screen inset-0 bg-white loading-overlay bg-blend-lighten translate-y-[-50px] z-[10]">
      <div className="flex flex-col items-center justify-center h-screen">
        <img src="/logo192.png" alt="loading" className="w-1/6 animate-pulse" />
        <h2 className="text-xl font-bold text-primary mt-4">
          {t("Accediendo con Microsoft")} ...
        </h2>
        <div className="border-gray-300 h-10 w-10 animate-spin rounded-full border-4 border-t-primary mt-2" />
        {seconds < 0 && navigator.onLine && (
          <button
            onClick={async () => {
              localStorage.removeItem("lgnRqe");
              window.location.reload();
            }}
            className="text-primary mt-4 uppercase font-bold bg-gray-50 p-4 rounded-md"
          >
            {t("Haz clic aquí para reintentarlo")}
          </button>
        )}
      </div>
    </div>
  );
};

export default Login;
