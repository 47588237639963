import { createContext, useEffect, useState } from "react";
import { useIndexedDBStore } from "use-indexeddb";

const headers = {
  "X-Public-Key": process.env.REACT_APP_PUBLIC_KEY,
  "X-Private-Key": process.env.REACT_APP_PRIVATE_KEY,
  "Content-Type": "application/json",
  Accept: "application/json",
};

interface LoginContextType {
  privateKey: string;
  publicKey: string;
  loaded: boolean;
  loginRequired: boolean;
  getMsUrl: () => Promise<{ url: string }>;
  setClientKey: (key: string) => void;
  setClientSecret: (secret: string) => void;
  setLoginRequired: (required: boolean) => void;
}

const LoginContext = createContext<LoginContextType>({
  privateKey: "",
  publicKey: "",
  loaded: false,
  loginRequired: true,
  getMsUrl: () => Promise.resolve({ url: "" }),
  setClientKey: () => {},
  setClientSecret: () => {},
  setLoginRequired: () => {},
});

export const LoginProvider = ({ children }: any) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loginRequired, setLoginRequired] = useState<boolean>(true);
  const [privateKey, setPrivateKey] = useState<string>("");
  const [publicKey, setPublicKey] = useState<string>("");
  const generalDb = useIndexedDBStore("general");

  useEffect(() => {
    getKeys();
  }, []);

  /**
   * Get the keys from the database
   */
  const getKeys = async () => {
    try {
      const data: any = (await generalDb?.getAll()) || [];
      if (data.length > 0) {
        // find the last element containing non empty keys or undefided
        const { private_key: privateKey, public_key: publicKey } =
          data.reduceRight((acc: any, curr: any) => {
            if (!acc.private_key) {
              return curr;
            }
            return acc;
          });

        if (privateKey && publicKey) {
          localStorage.setItem("lgnRqe", "false");
          setPrivateKey(privateKey);
          setPublicKey(publicKey);
          setLoaded(true);
          setLoginRequired(false);
          return;
        } else {
          localStorage.setItem("lgnRqe", "true");
          await generalDb.deleteAll();
          getMsUrl().then(({ url }: { url: string }) => {
            if (url !== "") {
              window.location.replace(url);
            }
            return null;
          });
        }
      } else {
        getMsUrl().then(({ url }: { url: string }) => {
          if (url !== "") {
            window.location.replace(url);
          }
          return null;
        });
      }
    } catch (e) {
      localStorage.setItem("lgnRqe", "true");
      getMsUrl().then(({ url }: { url: string }) => {
        localStorage.setItem("lgnRqe", "false");
        setLoginRequired(false);
        if (url !== "") {
          window.location.replace(url);
        }

        return null;
      });
      console.log(e);
    }
  };

  const getMsUrl = async () => {
    const storageLoginRequired = localStorage.getItem("lgnRqe");
    if (storageLoginRequired === "false") {
      return { url: "" };
    }
    return fetch(`${process.env.REACT_APP_API_URL}/auth/login-with-microsoft`, {
      method: "GET",
      // @ts-expect-error - Custom headers
      headers: headers,
    }).then((data) => {
      if (data.ok) {
        return data.json();
      }
    });
  };

  /**
   * On Ms login url will provide a code
   */
  useEffect(() => {
    if (window.location.search?.includes("code=") && loginRequired) {
      const code = window.location.search.split("code=")[1].split("&")[0];
      // delete search from url
      window.history.replaceState({}, document.title, "/");
      if (code) {
        localStorage.setItem("lgnRqe", "false");
        loginWithMs(code);
      }
    } else if (window.location.search.includes("error=")) {
      setLoginRequired(true);
      localStorage.removeItem("lgnRqe");
    }
  }, [window.location.search]);

  const loginWithMs = async (code: string) => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/login-with-microsoft`, {
      method: "POST",
      body: JSON.stringify({ code }),
      // @ts-expect-error - Custom headers
      headers: headers,
    })
      .then((data) => {
        if (data.ok) {
          return data.json();
        }
        throw data;
      })
      .then((data) => {
        setPrivateKey(data.private);
        setPublicKey(data.public);
        setLoaded(true);
        localStorage.setItem("lgnRqe", "false");
        setLoginRequired(false);
      })
      .catch((err) => {
        console.log(err);
        localStorage.setItem("lgnRqe", "true");
        setLoaded(false);
        setLoginRequired(true);
        getKeys();
      });
  };

  return (
    <LoginContext.Provider
      value={{
        privateKey,
        publicKey,
        loaded,
        loginRequired,
        getMsUrl,
        setClientKey: setPrivateKey,
        setClientSecret: setPublicKey,
        setLoginRequired,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
