import { FocusEvent, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pharmacy } from "../types";
import OrdersContext from "../context/OrdersContext";
import { createPortal } from "react-dom";

const Suggestions = ({
  suggestions,
  setSelectedPharmacy,
  setSuggestions,
  inputRef,
}: any) => {
  return (
    <div className="absolute top-20 w-1/6 h-auto max-h-[300px] z-[100] overflow-auto bg-white rounded-xs shadow-xl">
      {suggestions.map((pharmacy: Pharmacy) => (
        <div
          key={`pharmacy_${pharmacy.code}`}
          className="flex flex-row justify-between items-center border-b hover:bg-primary hover:text-white cursor-pointer transition-colors duration-200 ease-in-out"
          onClick={() => {
            setSelectedPharmacy(pharmacy.code);
            setSuggestions([]);
            if (inputRef.current)
              inputRef.current.value = `${pharmacy.code} - ${pharmacy.name}`;
          }}
        >
          <div className="flex flex-col p-1">
            <p className="text-sm">{pharmacy.name}</p>
            <p className="text-xs">{pharmacy.code}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const PharmacySelector: React.FC = () => {
  const {
    pharmacies,
    setSelectedPharmacy,
    selectedPharmacy,
    cart,
    reposition,
    setReplenishment,
    replenishment,
    setRepositionDiscount,
  } = useContext(OrdersContext);

  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [suggestions, setSuggestions] = useState<Pharmacy[]>([]);

  const getSuggestions = (search: string) => {
    if (search.length < 3) {
      setSuggestions([]);
      return;
    }

    const filteredPharmacies = pharmacies.filter(
      (pharmacy: Pharmacy) =>
        pharmacy.name.toLowerCase().includes(search.toLowerCase()) ||
        pharmacy.code.toLowerCase().includes(search.toLowerCase()) ||
        (pharmacy.code + " - " + pharmacy.name)
          .toLowerCase()
          .includes(search.toLowerCase())
    );

    setSuggestions(filteredPharmacies);
  };

  const isRepositionDisabled = useMemo(() => {
    const productInCart = cart.find((p: any) => p.reposition === false);
    return selectedPharmacy === "" || productInCart || cart?.length > 0;
  }, [reposition, cart, selectedPharmacy]);

  return (
    <div className="flex flex-row items-end justify-start w-full gap-4">
      <div
        className="flex flex-col items-start justify-start w-1/6"
        id="selector"
      >
        <label
          className={`${
            selectedPharmacy === "" ? "text-[14px]" : "text-sm"
          } text-black font-bold mt-4 text-left w-full`}
          htmlFor="pharmacy_name"
        >
          {t("Farmacia")}
        </label>
        <input
          name="pharmacy_name"
          id="pharmacy_name"
          placeholder={t("Buscar farmacia")}
          type="search"
          className={`text-secondary rounded-xs w-full text-sm p-2 border-1 border-gray-200 focus:border-primary focus:outline-none ${
            selectedPharmacy === ""
              ? "!border-black !border-[1.5px] focus:animate-none "
              : ""
          } focus:animation-none`}
          onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) => {
            getSuggestions(e.target.value);
            if (e.target.value.trim() === "") {
              setSelectedPharmacy("");
            }
          }}
          autoComplete="noppe"
          ref={inputRef}
        />
      </div>

      {suggestions.length > 0 &&
        createPortal(
          <Suggestions
            suggestions={suggestions}
            setSelectedPharmacy={setSelectedPharmacy}
            setSuggestions={setSuggestions}
            inputRef={inputRef}
          />,
          document.getElementById("selector") as HTMLElement
        )}

      <label className="inline-flex items-center cursor-pointer px-2 mb-2">
        <input
          type="checkbox"
          className="sr-only peer"
          disabled={isRepositionDisabled}
          checked={replenishment}
          name="replenishment"
          onChange={(e) => {
            setReplenishment(e.target.checked);

            if (e.target.checked === false) {
              setRepositionDiscount(0);
            }
          }}
        />
        <div
          className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-primary rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"
          style={{
            cursor: isRepositionDisabled ? "not-allowed" : "pointer",
            opacity: selectedPharmacy === "" ? 0.5 : 1,
          }}
        />
        <span
          className="ms-3 text-sm font-medium text-gray-900 pl-2"
          style={{
            cursor: isRepositionDisabled ? "not-allowed" : "pointer",
            color: selectedPharmacy === "" ? "#ccc" : "#000",
          }}
        >
          {t("Reposición")}
        </span>
      </label>

      {replenishment && (
        <div className="flex flex-col items-start justify-start w-30">
          <label
            className="text-sm text-black font-bold mt-4 text-left w-full"
            style={{
              color: selectedPharmacy === "" ? "#ccc" : "#000",
            }}
          >
            {t("Descuento")}
          </label>
          <input
            type="number"
            className="text-secondary rounded-xs w-[4rem] text-sm p-2 border-1 border-gray-200 focus:border-primary focus:outline-none"
            placeholder="0 %"
            defaultValue={0}
            disabled={selectedPharmacy === ""}
            autoFocus
            min={0}
            max={100}
            style={{
              opacity: selectedPharmacy === "" ? 0.5 : 1,
            }}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (value < 0 || value > 100) {
                e.target.value = value < 0 ? "0" : "100";
                return 0;
              }
              setRepositionDiscount(Number(e.target.value));
            }}
            onBlur={(event: FocusEvent<HTMLInputElement, Element>) => {
              // on blur, change input type to text and add % symbol
              event.target.type = "text";
              event.target.value = event.target.value + " %";
            }}
            onFocus={(event: FocusEvent<HTMLInputElement, Element>) => {
              // on focus, remove % symbol
              event.target.value = event.target.value.replace(" %", "");
              event.target.type = "number";
              // select the value
              event.target.select();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PharmacySelector;
