import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OrdersContext from "../context/OrdersContext";
import { Product } from "../types";
import {
  calculateDiscount,
  calculateDtoLine,
  calculateMargin,
  calculatePriceLine,
  calculateRepositionMargin,
  formatNumber,
  getTotal,
  getTotalMargin,
  minimunQty,
} from "../utils";
import DeleteBundlePackModal from "./DeleteBundlePackModal";
import ConfirmOrderModal from "./ConfirmOrderModal";

const Cart = ({
  generateOrder,
}: {
  generateOrder: (order: any) => Promise<boolean>;
}) => {
  const { t } = useTranslation();
  const {
    cart,
    orders,
    selectedPharmacy,
    setCart,
    setOrders,
    setSelectedPharmacy,
    setOpenBundlePackModal,
    orderError,
    setOrderError,
    replenishment,
    repositionDiscount,
    setReposition,
    setRepositionDiscount,
    setReplenishment,
  } = useContext(OrdersContext);

  const MIN_CART_AMOUNT = replenishment
    ? 0
    : Number(process.env.REACT_APP_MIN_ORDER_AMOUNT);

  const [showError, setShowError] = useState(false);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [confirmOrderModal, setConfirmOrderModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [deleteBundlePackModal, setDeleteBundlePackModal] =
    useState<number>(-1);

  const removeFromCart = (cn: string) => {
    // delete product from cart if it's not a bundle pack
    const newCart = cart.filter((product: Product) => {
      if (product.cn === cn && product.from_bundle_id === undefined) {
        return false;
      }
      return true;
    });

    setCart(newCart);
  };

  const makeOrder = async () => {
    setLoading(true);
    if (cart.length === 0) return;
    let cartTotal = 0;

    if (replenishment && repositionDiscount !== 0) {
      // minimum 8 products
      let totalQty = 0;
      cart.forEach((product: any) => {
        if (product.pvl !== 0) {
          totalQty += product.quantity;
        }
      });
      if (totalQty < 8) {
        setShowError(true);
        setConfirmOrderModal(false);
        setLoading(false);
        return;
      }
    }

    if (!replenishment && repositionDiscount === 0) {
      cart.forEach((product: any) => {
        cartTotal += product.pvl * product.quantity;
      });
      if (cartTotal < MIN_CART_AMOUNT) {
        setShowError(true);
        setLoading(false);
        return;
      }
    }

    const order = {
      code: selectedPharmacy,
      products: cart.map((product: any) => ({
        identifier: product.cn,
        quantity: product.quantity,
        discount: replenishment
          ? repositionDiscount
          : calculateDtoLine(product, cart),
        margin: replenishment
          ? calculateRepositionMargin(product, repositionDiscount)
          : calculateMargin(product, product.quantity),
        total: calculatePriceLine(product, cart, repositionDiscount),
        total_gross: product.pvl * product.quantity,
        equivalence_surcharge: product.equivalence_surcharge,
        pvl: product.pvl,
        pvp_recommended: product.pvp_recommended,
        vat: product.vat,
      })),
      margin: getTotalMargin(cart, repositionDiscount),
      total: getTotal(cart, repositionDiscount),
      total_gross: getTotalBrut(),
    };

    setOrders([...orders, order]);
    const response = await generateOrder(order);
    if (response) {
      setShowOrderConfirmation(true);

      setTimeout(() => {
        setShowOrderConfirmation(false);
      }, 3000);
    }

    setCart([]);
    setReposition(false);
    setReplenishment(false);
    setRepositionDiscount(0);
    setSelectedPharmacy("");
    const pharmacySelector = document.getElementById(
      "pharmacy_name"
    ) as HTMLInputElement;
    if (pharmacySelector) pharmacySelector.value = "";
    setShowError(false);
    setConfirmOrderModal(false);
    setLoading(false);
  };

  const deleteCart = () => {
    setCart([]);
  };

  const getTotalBrut = () => {
    let total = 0;
    cart.forEach((product: any) => {
      total += product.pvl * product.quantity;
    });

    return total;
  };

  const totalDto = useMemo(() => {
    let total = 0;
    cart.forEach((product: any) => {
      total +=
        repositionDiscount !== 0
          ? repositionDiscount
          : calculateDtoLine(product, cart);
    });
    return total / cart.length;
  }, [cart, repositionDiscount, replenishment]);

  useEffect(() => {
    if (repositionDiscount !== 0) return;
    let total = 0;
    cart.forEach((product: any) => {
      total +=
        repositionDiscount || calculateDiscount(product)[1] * product.quantity;
    });

    if (total >= MIN_CART_AMOUNT) setShowError(false);
  }, [cart, repositionDiscount]);

  const onDeleteBundlePack = (product: Product) => {
    const bundlePackProducts = cart.filter(
      (p: any) => p.from_bundle_id === product.from_bundle_id
    );

    if (bundlePackProducts.length === 1) {
      onDeleteConfirm(product.from_bundle_id!);
      return;
    }

    setDeleteBundlePackModal(product.from_bundle_id!);
  };

  const onDeleteConfirm = (bundle_id?: number) => {
    const id = bundle_id ? bundle_id : deleteBundlePackModal;
    const newCart = cart.filter(
      (product: any) => product.from_bundle_id !== id
    );
    setCart(newCart);
    setDeleteBundlePackModal(-1);
  };

  // check if the cart has at least 8 products with pvl !== 0 if reposition is true
  const minTotalQty = useMemo(() => {
    let totalQty = 0;
    cart.forEach((product: any) => {
      if (product.pvl !== 0) {
        totalQty += product.quantity;
      }
    });
    return replenishment && totalQty >= 8;
  }, [cart, replenishment]);

  return (
    <div className="relative w-full !text-sm pb-4">
      <div className="flex w-full justify-start flex-col grow border">
        <h2 className="text-lg font-bold text-center my-0.5">{t("Carrito")}</h2>
        {cart?.length > 0 ? (
          <div className="w-full h-full overflow-hidden flex flex-col">
            <table className="table-auto">
              <thead className="border-b">
                <tr>
                  <th className="px-2 py-2 md:w-full">CN</th>
                  <th className="px-2 py-2 md:min-w-[100px]">
                    {t("Cantidad")}
                  </th>
                  <th className="px-2 py-2 md:min-w-[110px]">PVL</th>
                  <th className="px-2 py-2 md:min-w-[90px]">{t("Dto.")}</th>
                  <th className="px-2 py-2 md:min-w-[110px]">{t("Neto")}</th>
                  <th className="px-2 py-2 max-w-[40px]"></th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {cart.map((product: Product) => (
                  <tr
                    key={`cart_${product.cn}${
                      product.from_bundle_id
                        ? `_bundle_${product.from_bundle_id}`
                        : ""
                    }`}
                    className="divide-x"
                  >
                    <td className="px-2 py-1 text-center">
                      <span className="flex justify-center items-center gap-1 h-full">
                        <span
                          style={{
                            userSelect: "all",
                          }}
                        >
                          {product.cn}
                        </span>
                        {product.from_bundle_id && (
                          <span
                            className="flex justify-center relative"
                            onClick={() => {
                              setOpenBundlePackModal(product.from_bundle_id);
                            }}
                            title={t("Surtido")}
                          >
                            <svg
                              className="hover:fill-primary cursor-pointer"
                              width="16px"
                              height="16px"
                              viewBox="0 0 36 36"
                              version="1.1"
                              preserveAspectRatio="xMidYMid meet"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <path
                                className="clr-i-outline clr-i-outline-path-1"
                                d="M32.43,8.35l-13-6.21a1,1,0,0,0-.87,0l-15,7.24a1,1,0,0,0-.57.9V26.83a1,1,0,0,0,.6.92l13,6.19a1,1,0,0,0,.87,0l15-7.24a1,1,0,0,0,.57-.9V9.25A1,1,0,0,0,32.43,8.35ZM19,4.15,29.93,9.37l-5.05,2.44L14.21,6.46ZM17,15.64,6,10.41l5.9-2.85L22.6,12.91ZM5,12.13,16,17.4V31.46L5,26.2ZM18,31.45V17.36l13-6.29v14.1Z"
                              ></path>
                              <rect
                                x="0"
                                y="0"
                                width="36"
                                height="36"
                                fillOpacity="0"
                              />
                            </svg>
                            <span className="text-primary text-xs">
                              <sup>{product.from_bundle_id}</sup>
                            </span>
                          </span>
                        )}
                      </span>
                    </td>
                    <td className="px-2 py-1 text-center">
                      <div
                        className={`flex ${
                          product.from_bundle_id !== undefined
                            ? "justify-center"
                            : "justify-between"
                        } items-center gap-2 h-full`}
                      >
                        {product.from_bundle_id === undefined && (
                          <button
                            name="minus"
                            className={`text-primary/50 bg-gray-100 rounded ${
                              product.quantity ===
                              minimunQty(product, replenishment)
                                ? "opacity-50 cursor-not-allowed"
                                : "hover:text-primary/100"
                            }`}
                            onMouseDown={() => {
                              const newCart = cart.map((p: any) => {
                                if (p.from_bundle_id) return p;
                                const min = minimunQty(product, replenishment);
                                if (p.cn === product.cn && p.quantity > min) {
                                  p.quantity -= 1;
                                  p.total = p.quantity * p.pvl;
                                }
                                return p;
                              });
                              setCart(newCart);
                            }}
                          >
                            {/* minus icon */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M20 12H4"
                              />
                            </svg>
                          </button>
                        )}
                        {product.quantity}

                        {product.from_bundle_id === undefined && (
                          <button
                            name="plus"
                            className="text-primary/50 hover:text-primary/100 bg-gray-100 rounded"
                            onMouseDown={() => {
                              const newCart = cart.map((p: any) => {
                                if (p.from_bundle_id) return p;
                                if (p.cn === product.cn) {
                                  p.quantity = parseInt(p.quantity) + 1;
                                  p.total = p.quantity * p.pvl;
                                }
                                return p;
                              });
                              setCart(newCart);
                            }}
                          >
                            {/* plus icon */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    </td>
                    <td className="px-2 py-1 text-center">
                      {formatNumber(product.pvl)} €
                    </td>
                    {/* Discount */}
                    <td className="px-2 py-1 text-center">
                      {replenishment
                        ? formatNumber(repositionDiscount)
                        : formatNumber(calculateDtoLine(product, cart))}{" "}
                      %
                    </td>
                    {/* Net price */}
                    <td className="px-2 py-1 text-center">
                      {formatNumber(
                        calculatePriceLine(
                          product,
                          cart,
                          replenishment,
                          repositionDiscount
                        )
                      )}{" "}
                      €
                    </td>
                    <td className="px-2 py-1 text-center hover:text-red-500 focus:text-red-500 w-full">
                      <button
                        className="w-full flex justify-center items-center"
                        onClick={() => {
                          if (product.from_bundle_id !== undefined) {
                            onDeleteBundlePack(product);
                          } else {
                            removeFromCart(product.cn);
                          }
                        }}
                      >
                        {/* trash icon */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="border-t">
                <tr className="text-left">
                  <td colSpan={2} className="px-2 pt-4">
                    {t("Total Bruto")}
                  </td>
                  <td></td>
                  <td></td>
                  <td colSpan={2} className="px-1 pt-4 w-full text-right">
                    {formatNumber(getTotalBrut())} €
                  </td>
                </tr>
                <tr className="text-left">
                  <td colSpan={2} className="px-2">
                    {t("Total Margen")}
                  </td>
                  <td></td>
                  <td></td>
                  <td colSpan={2} className="px-1 w-full text-right">
                    {formatNumber(
                      getTotalMargin(cart, replenishment, repositionDiscount)
                    )}
                    %
                  </td>
                </tr>
                <tr className="text-left">
                  <td colSpan={2} className="px-2">
                    {t("Total Dto. med")}
                  </td>
                  <td></td>
                  <td></td>
                  <td colSpan={2} className="px-1 w-full text-right">
                    {replenishment
                      ? formatNumber(repositionDiscount)
                      : formatNumber(totalDto)}{" "}
                    %
                  </td>
                </tr>
                <tr className="text-left bg-primary/10 font-bold">
                  <td colSpan={2} className="px-2 text-base">
                    {t("Total Neto")}
                  </td>
                  <td></td>
                  <td></td>
                  <td
                    colSpan={2}
                    className="px-1 py-2 w-full text-right text-base"
                  >
                    {formatNumber(
                      getTotal(cart, replenishment, repositionDiscount)
                    )}{" "}
                    €
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : showOrderConfirmation ? (
          <p className="text-center text-green-600 text-base z-[100]">
            {t("Pedido generado correctamente")}
          </p>
        ) : (
          <p className="text-center">{t("El carrito está vacío")}</p>
        )}
      </div>

      {cart?.length > 0 && (
        <div className="flex justify-center flex-col w-full items-center relative">
          {showError && (
            <p className="text-red-600 text-center text-sm">
              {replenishment
                ? t(
                    "El pedido debe ser de al menos de {{MIN_CART_AMOUNT}} € brutos para poder realizarlo.",
                    {
                      MIN_CART_AMOUNT,
                    }
                  )
                : t(
                    "El pedido de reposición debe tener al menos 8 unidades para poder realizarlo."
                  )}
            </p>
          )}

          <div className="flex flex-row w-full gap-4">
            {/* delete all */}
            <button
              className="p-1 rounded-xs w-1/3 mt-4 text-white border-2 text-sm bg-secondary"
              onClick={deleteCart}
            >
              {t("Vaciar")}
            </button>

            <button
              className={`text-white p-1 rounded-xs w-full mt-4 text-sm font-bold ${
                !replenishment
                  ? getTotalBrut() < MIN_CART_AMOUNT
                    ? "bg-disabled-primary cursor-not-allowed"
                    : "bg-primary"
                  : replenishment && minTotalQty
                  ? "bg-primary"
                  : "bg-disabled-primary cursor-not-allowed pointer-events-none"
              }`}
              onClick={() => {
                if (selectedPharmacy === "") {
                  return null;
                }
                setConfirmOrderModal(true);
              }}
              disabled={getTotalBrut() < MIN_CART_AMOUNT}
            >
              {t("Hacer pedido")}
            </button>
          </div>
        </div>
      )}

      {deleteBundlePackModal !== -1 && (
        <DeleteBundlePackModal
          onDeleteConfirm={onDeleteConfirm}
          onCancel={() => setDeleteBundlePackModal(-1)}
        />
      )}

      {confirmOrderModal && (
        <ConfirmOrderModal
          onConfirm={makeOrder}
          onCancel={() => setConfirmOrderModal(false)}
          loading={loading}
        />
      )}
      {orderError && (
        <div className="fixed bottom-0 left-0 w-full h-12 bg-red-500 text-white flex justify-center items-center">
          {typeof orderError === "object"
            ? orderError?.message +
              ": " +
              Object.values(orderError.errors || {})?.join(", ")
            : t("Error al enviar el pedido, se reintentará más tarde")}
          <button
            className="ml-4 bg-white text-red-500 px-2 py-1 rounded-xs"
            onClick={() => {
              setOrderError(false);
            }}
          >
            {t("Cerrar")}
          </button>
        </div>
      )}
    </div>
  );
};

export default Cart;
