/**
 * useData hook is used to fetch data from the API or indexedDB
 *
 */

import { useRef, useContext, useEffect, useState, useCallback } from "react";
import { useIndexedDBStore } from "use-indexeddb";
import OrdersContext from "../context/OrdersContext";
import { Pharmacy, Product } from "../types";
import LoginContext from "../context/LoginContext";
import {
  calculateDtoLine,
  calculateMargin,
  calculatePriceLine,
  getTotal,
  getTotalMargin,
} from "../utils";

const apiUrl = process.env.REACT_APP_API_URL;
let headers = {
  headers: {
    "X-Public-Key": process.env.REACT_APP_PUBLIC_KEY,
    "X-Private-Key": process.env.REACT_APP_PRIVATE_KEY,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

const useData = () => {
  const { publicKey, privateKey, loaded, setLoginRequired } =
    useContext(LoginContext);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pendingOrders, setPendingOrders] = useState<any[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  // abortcontroller
  const abortController = useRef(new AbortController());

  const { setOrders, setPharmacies, setOrderError } = useContext(OrdersContext);
  const orderDb = useIndexedDBStore("order");
  const productDb = useIndexedDBStore("product");
  const pharmacyDb = useIndexedDBStore("pharmacy");
  const generalDb = useIndexedDBStore("general");

  const maxRetry = 3;
  const retries = useRef(0);

  useEffect(() => {
    if (!navigator.onLine) {
      setReady(true);
    }
  }, []);

  /**
   * Set the headers for the API requests
   */
  useEffect(() => {
    if (
      loaded &&
      typeof publicKey === "string" &&
      publicKey.trim() !== "" &&
      typeof privateKey === "string" &&
      privateKey.trim() !== ""
    ) {
      headers = {
        headers: {
          ...headers.headers,
          // @ts-expect-error - Custom headers
          "X-Personal-Public-Key": publicKey,
          "X-Personal-Private-Key": privateKey,
        },
      };
      abortController.current = new AbortController();
      generalDb.add({
        private_key: privateKey,
        public_key: publicKey,
        last_update_at: new Date(),
      });
      setReady(true);
    }
  }, [loaded, privateKey, publicKey]);

  /**
   * Sync data from the database when the app is offline
   */
  const syncDataFromDb = useCallback(async () => {
    const orders = await orderDb.getAll();
    const products = await productDb.getAll();
    const pharmaciesDb = await pharmacyDb.getAll();

    setPendingOrders(orders);
    setProducts(products as Product[]);
    setPharmacies(pharmaciesDb);
    setReady(true);
    setLoading(false);
  }, [orderDb, productDb, pharmacyDb]);

  /**
   * Check if pending orders are in the database and set the pending orders state, only when app starts
   */
  useEffect(() => {
    if (loaded) {
      const orders = orderDb.getAll();
      orders.then((data) => {
        setPendingOrders(data);
      });
    }
  }, [loaded]);

  /**
   * Fetch data from the API when the app is online
   */
  const fetchData = async (signal?: AbortSignal) => {
    setLoading(true);
    new Promise((resolve, reject) => {
      if (signal) {
        signal.addEventListener("abort", () => {
          reject();
        });
      }
      getProducts();
      getPharmacies();
      resolve(setLoading(false));
    });

    await generalDb.add({ last_update_at: new Date() });
  };

  /**
   * Fetches products from the API and updates the state with the retrieved data.
   * If the response is successful, the products are added to the productDb and set in the state.
   * If the response status is 401, it gets new personal keys and fetches the data again.
   * If there is an error, it logs the error and syncs the data from the database.
   */
  const getProducts = async () => {
    fetch(`${apiUrl}/products`, {
      method: "GET",
      ...(headers as any),
      signal: abortController.current.signal,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data.length > 0) {
          productDb.deleteAll();
          data.forEach((product: Product) => {
            productDb.add(product);
          });
          setProducts(data);
        }
      })
      .catch(async (error) => {
        console.error(error);
        // if error 401, get new keys
        if (error.status === 401) {
          setLoginRequired(true);
          abortController.current.abort();
          localStorage.removeItem("lgnRqe");
          await generalDb.deleteAll();
          window.location.reload();
        }
        syncDataFromDb();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Fetches pharmacies from the API and updates the state with the retrieved data.
   * If the response is successful, the pharmacies are added to the productDb and set in the state.
   * If the response status is 401, it gets new personal keys and fetches the data again.
   * If there is an error, it logs the error and syncs the data from the database.
   */
  const getPharmacies = async () => {
    fetch(`${apiUrl}/organizations`, {
      method: "GET",
      ...(headers as any),
      signal: abortController.current.signal,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data.length > 0) {
          pharmacyDb.deleteAll();
          data.forEach((pharmacy: Pharmacy) => {
            pharmacyDb.add({
              code: pharmacy.code,
              name: pharmacy.name,
            });
          });
          setPharmacies(data);
        }
      })
      .catch(async (error) => {
        console.error(error);
        // syncDataFromDb();
      });
  };

  const generateOrder = async (order: {
    code: string; // pharmacy code
    products: Product[];
  }) => {
    // add to daba base
    orderDb.add(order);
    // add to pending orders
    setPendingOrders([...pendingOrders, order]);

    // dipatch event "update"
    const event = new CustomEvent("message", { detail: "update" });
    window.dispatchEvent(event);
    return true;
  };

  // send order to the server when the app is online and delete it from the database
  useEffect(() => {
    if (navigator.onLine && pendingOrders.length > 0) {
      postOrders()
        .then((data) => {
          if (data) {
            orderDb.deleteAll();
            setOrders([]);
            setPendingOrders([]);
          }
        })
        .catch(async (error) => {
          if (error.status === 401 || error.status >= 500) {
            setOrderError(true);
            return null;
          } else if (error.status === 422) {
            // abort
            abortController.current.abort();
            migrateOrder(pendingOrders);
          }
          return null;
        });
    }
  }, [pendingOrders, orderDb, navigator.onLine]);

  const postOrders = async () => {
    return fetch(`${apiUrl}/orders`, {
      method: "POST",
      body: JSON.stringify({ orders: pendingOrders }),
      ...(headers as any),
    })
      .then((response) => {
        if (response.ok) {
          setOrderError(false);
          return response.json();
        }

        throw response;
      })
      .catch(async (error) => {
        setOrderError(error);
        if (error.status === 401 || error.status >= 500) {
          return null;
        } else if (error.status === 422) {
          // abort
          abortController.current.abort();
          if (retries.current <= maxRetry) {
            return migrateOrder(pendingOrders);
          }
          return null;
        }
        return null;
      });
  };

  const migrateOrder = async (order: any[]) => {
    // check if order has keys margin, total, total_gross, if not, generate them
    const newOrders = order.map((cartProducts: any) => {
      const newOrder = { ...cartProducts };
      if (!newOrder.margin || !newOrder.total || !newOrder.total_gross) {
        const newProducts = newOrder.products.map((product: any) => {
          const p = products.find(
            (p: any) => p.cn === product.identifier
          )! as any;

          const newProduct = {
            ...product,
            ...p,
          };
          const discount = calculateDtoLine(product, cartProducts);
          const margin = calculateMargin(newProduct, newProduct.quantity);
          const total = calculatePriceLine(newProduct, cartProducts);
          const total_gross = newProduct.pvl * newProduct.quantity;

          newProduct.discount = discount;
          newProduct.margin = margin;
          newProduct.total = total;
          newProduct.total_gross = total_gross;

          return newProduct;
        });
        newOrder.products = newProducts;
      }
      const margin = getTotalMargin(newOrder.products);
      const total = getTotal(newOrder.products);
      let total_gross = 0;
      newOrder.products.forEach((product: any) => {
        total_gross += product.pvl * product.quantity;
      });

      newOrder.total = total;
      newOrder.margin = margin;
      newOrder.total_gross = total_gross;

      return newOrder;
    });

    retries.current += 1;
    setPendingOrders(newOrders);
  };

  return {
    fetchData,
    generateOrder,
    loading,
    pendingOrders,
    products,
    syncDataFromDb,
    ready,
    setReady,
  };
};

export default useData;
