import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface LoadingOverlayProps {
  counter?: number;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ counter }) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div className="absolute w-screen h-screen inset-0 bg-white loading-overlay bg-blend-lighten translate-y-[-50px] z-[10]">
      <div className="flex flex-col items-center justify-center h-screen">
        <img src="/logo192.png" alt="loading" className="w-1/6 animate-pulse" />

        {counter === 0 ? (
          <button
            className="bg-primary text-white px-4 py-2 mt-4 rounded-md mt-10"
            onClick={() => (window.location.href = window.location.href)}
          >
            {t("Recargar")}
          </button>
        ) : (
          <>
            <h2 className="text-xl font-bold text-primary mt-4">
              {t("Sincronizando")} ...
            </h2>
            <div className="border-gray-300 h-10 w-10 animate-spin rounded-full border-4 border-t-primary mt-2" />
          </>
        )}
      </div>
    </div>
  );
};

export default LoadingOverlay;
